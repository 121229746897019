<template>
  <div v-if="locationState">
    <nav class="navbar">
      <a @click="$router.go(-1)" class="navbar-item has-icon">
        <span class="icon is-small">
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </span>
        <span>Back</span>
      </a>

      <div class="navbar-end">
        <div class="navbar-item">
          <LocationEditRight />
        </div>
      </div>
    </nav>

    <ui-page-hero :title="locationState.Name" />

    <section class="section">
      <div class="">
        <div class="tabs is-boxed is-small mb-6" key="tabs1">
          <ul>
            <li
              @click="setVisibleItem('data')"
              :class="{ 'is-active': locationDetail.visibleItem === 'data' }"
            >
              <a>Location data</a>
            </li>
            <li
              @click="setVisibleItem('settings')"
              :class="{
                'is-active': locationDetail.visibleItem === 'settings',
              }"
            >
              <a>Settings</a>
            </li>
            <li
              @click="setVisibleItem('open')"
              :class="{ 'is-active': locationDetail.visibleItem === 'open' }"
            >
              <a>Opening hours</a>
            </li>
            <li
              @click="setVisibleItem('text')"
              :class="{ 'is-active': locationDetail.visibleItem === 'text' }"
            >
              <a>Descriptions</a>
            </li>
            <li
              @click="setVisibleItem('images')"
              :class="{ 'is-active': locationDetail.visibleItem === 'images' }"
            >
              <a>Images</a>
            </li>
            <li
              @click="setVisibleItem('videos')"
              :class="{ 'is-active': locationDetail.visibleItem === 'videos' }"
            >
              <a>Videos</a>
            </li>
            <li
              @click="setVisibleItem('yield')"
              :class="{ 'is-active': locationDetail.visibleItem === 'yield' }"
            >
              <a>Pricing &amp; yield</a>
            </li>
            <li
              @click="setVisibleItem('accounts')"
              :class="{
                'is-active': locationDetail.visibleItem === 'accounts',
              }"
            >
              <a>Accounts</a>
            </li>
            <li
              @click="setVisibleItem('spaces')"
              :class="{ 'is-active': locationDetail.visibleItem === 'spaces' }"
            >
              <a>Spaces</a>
            </li>
            <li
              @click="setVisibleItem('options')"
              :class="{ 'is-active': locationDetail.visibleItem === 'options' }"
            >
              <a>Options</a>
            </li>
            <li
              @click="setVisibleItem('vouchers')"
              :class="{
                'is-active': locationDetail.visibleItem === 'vouchers',
              }"
            >
              <a>Vouchers</a>
            </li>

            <li
              @click="setVisibleItem('subscriptions')"
              :class="{
                'is-active': locationDetail.visibleItem === 'subscriptions',
              }"
            >
              <a>Subscriptions</a>
            </li>

            <li
              @click="setVisibleItem('stats')"
              :class="{
                'is-active': locationDetail.visibleItem === 'stats',
              }"
            >
              <a>Stats</a>
            </li>
          </ul>
        </div>

        <transition name="fade" mode="out-in">
          <LocationDetail
            v-if="locationDetail.visibleItem === 'data'"
            @goToItem="setVisibleItem"
          />

          <LocationSettings v-if="locationDetail.visibleItem === 'settings'" />

          <OpeningHours v-if="locationDetail.visibleItem === 'open'" />

          <LocationDescription v-if="locationDetail.visibleItem === 'text'" />

          <LocationImages v-if="locationDetail.visibleItem === 'images'" />

          <LocationVideos v-if="locationDetail.visibleItem === 'videos'" />

          <LocationSubscriptions
            v-if="locationDetail.visibleItem === 'subscriptions'"
          />

          <YieldList
            :locationId="locationId"
            :channelId="channelId"
            v-if="locationDetail.visibleItem === 'yield'"
          />

          <div v-if="locationDetail.visibleItem === 'spaces'">
            <div class="tabs is-boxed" key="tabs2">
              <ul>
                <li
                  :class="{
                    'is-active': subItem === 'spaces',
                  }"
                  key="spaceList"
                >
                  <a @click="subItem = 'spaces'">Spaces</a>
                </li>
                <li
                  :class="{
                    'is-active': subItem === 'check',
                  }"
                  key="spaceCheck"
                >
                  <a @click="subItem = 'check'">Check spaces</a>
                </li>
              </ul>
            </div>

            <div v-if="subItem === 'spaces'">
              <SpacesList />
            </div>

            <div v-if="subItem === 'check'">
              <CheckLocationSpaces />
            </div>
          </div>

          <OptionsList
            v-if="locationDetail.visibleItem === 'options'"
            :locationId="location.Id"
          />

          <AccountList v-if="locationDetail.visibleItem === 'accounts'" />

          <VouchersList v-if="locationDetail.visibleItem === 'vouchers'" />

          <StatsMonthYear
            :channelId="channel.Id"
            :locationId="location.Id"
            :meetingtypes="meetingtypes"
            v-if="locationDetail.visibleItem === 'stats'"
          />
        </transition>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import countryProvider from '@/providers/country'
import locationProvider from '@/providers/location'

export default {
  components: {
    AccountList: () => import('@/components/Accounts/ManageAccounts'),
    LocationDescription: () =>
      import('@/components/Locations/LocationDescription'),
    LocationDetail: () => import('@/components/Locations/LocationDetail'),
    LocationEditRight: () => import('@/components/Locations/LocationEditRight'),
    LocationImages: () => import('@/components/Locations/LocationImages'),
    LocationSettings: () => import('@/components/Locations/LocationSettings'),
    LocationVideos: () => import('@/components/Locations/LocationVideos'),
    LocationSubscriptions: () =>
      import('@/components/Locations/LocationSubscriptions'),
    OpeningHours: () =>
      import('@/components/Locations/OpeningHours/OpeningHours'),
    OptionsList: () => import('@/components/Options/OptionList'),
    SpacesList: () => import('@/components/Spaces/SpacesList'),
    VouchersList: () => import('@/components/Vouchers/VouchersList'),
    YieldList: () => import('@/components/Yield/YieldList'),
    CheckLocationSpaces: () =>
      import('@/components/Spaces/CheckLocationSpaces'),
    StatsMonthYear: () => import('@/components/Dashboards/StatsMonthYear'),
  },

  data() {
    return {
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      subItem: 'spaces',
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', [
      'location',
      'locationDetail',
      'locationState',
    ]),
    ...mapState('optionStore', ['optionsPage']),
    ...mapState('spaceStore', ['spacesPage']),

    meetingtypes() {
      let mt = []

      if (this.location) {
        for (let i = 0; i < this.location.Meetingtypes.length; i++) {
          mt.push(this.location.Meetingtypes[i].MeetingtypeId)
        }
      }

      return mt
    },
  },

  created() {
    this.getLocationState()

    this.optionsPage.locationId = this.locationId
    this.saveOptionsPage(this.optionsPage)

    this.spacesPage.locationId = this.locationId
    this.saveSpacesPage(this.spacesPage)
  },

  beforeDestroy() {
    this.setVisibleItem('data')
    this.clearOptionsPage()
    this.clearSpacesPage()

    this.setLocation(null)
    this.setLocationState(null)
  },

  methods: {
    ...mapMutations('locationStore', ['setLocation', 'setLocationState']),
    ...mapMutations('locationStore', ['setLocationDetail']),
    ...mapActions('optionStore', ['clearOptionsPage', 'saveOptionsPage']),
    ...mapActions('spaceStore', ['clearSpacesPage', 'saveSpacesPage']),

    getLocationState() {
      locationProvider.methods
        .getLocationState(this.locationId)
        .then((response) => {
          if (response.status === 200) {
            this.setLocationState(response.data)
          }
        })
        .catch((err) => {})
    },

    setVisibleItem(item) {
      this.locationDetail.visibleItem = item
      this.setLocationDetail(this.locationDetail)
    },

    getLockReason(typeId) {
      let reason = ''

      switch (typeId) {
        case 1:
          reason = 'No license agreement'
          break
        case 2:
          reason = 'Outstanding invoices'
          break
        case 3:
          reason = 'Suspecious behaviour'
          break
        case 4:
          reason = 'Other'
          break
      }

      return reason
    },

    GetManageTypeName(manageType) {
      let name = ''

      switch (manageType) {
        case 0:
          name = 'Full'
          break
        case 1:
          name = 'Workspace'
          break
        case 2:
          name = 'Serendipity machine'
          break
      }

      return name
    },

    getChannels() {
      channelProvider.methods
        .getChannels()
        .then((response) => {
          this.channels = response.data
        })
        .catch((err) => {})
    },

    getCountries() {
      countryProvider.methods.getAllCountries().then((response) => {
        this.countries = response.data

        this.getLocationData()
      })
    },

    getLocationSettings() {
      locationProvider.methods
        .getLocationSettings(this.locationId)
        .then((response) => {
          this.settings = response.data
        })
        .catch((err) => {})
    },

    getCountryName(countryId) {
      let self = this
      if (self.countries.length > 0) {
        let countries = self.countries.filter((c) => c.Id === Number(countryId))

        return countries[0].Name
      }
      return ''
    },

    updateLocationChannel(channelId) {
      let hasChannel = this.locationHasChannel(channelId)

      if (hasChannel) {
        location.methods.saveLocationChannel(this.locationId, channelId)
      } else {
        location.methods.deleteLocationChannel(this.locationId, channelId)
      }
    },

    locationHasChannel(channelId) {
      let self = this
      return self.locationChannelIds.indexOf(channelId) > -1
    },

    saveLocationSettings() {
      location.methods
        .saveLocationSettings(this.settings)
        .then((response) => {})
        .catch((err) => {})
    },
  },
}
</script>
