import axios from 'axios'

export default {
  methods: {
    async getAllCountries() {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/country`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      // store.commit('setCountries', response.data)
      return response
    },

    async getActiveCountries(channelId, showActive) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/country/active`,
        {
          params: {
            channelId: channelId,
            showActive: showActive
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      // store.commit('setCountries', response.data)
      return response
    }
  }
}
